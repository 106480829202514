
import Vue from 'vue';
import { C360Icon } from '@c360/ui';
import FeedToolExpansionPanel from './components/feedExpansionPanel.vue';

type HiddenCampaign = {
  campaignId: string;
  campaignName: string;
  advertiserId: string;
  advertiserName: string;
};

export default Vue.extend({
  name: 'FeedTools',
  components: { C360Icon, FeedToolExpansionPanel },
  data(): {
    isLoading: boolean;
    moveCampaignURL: string;
    moveCampaignTargetAdvertiser: string;
    changeDateURL: string;
    startDate: string;
    endDate: string;
    startDateMenu: boolean;
    endDateMenu: boolean;
    hideCampaignURL: string;
    moveValidation: string;
    changeValidation: string;
    hideValidation: string;
    searchResults: HiddenCampaign[];
    unhideCampaignURL: string | null;
    search: string;
    associateCampaignURL: string;
    associateFormValid: string;
    associateOrderURL: string;
    moveCampaignToProductURL: string;
    selectedProduct: string;
    moveToProductValidation: string;
    isSearching: boolean;
    campaignURLToClone: string;
    clonedCampignName: string;
    advertiserIdToClone: string;
    cloneFormValid: string;
  } {
    return {
      isLoading: false,
      moveCampaignURL: '',
      moveCampaignTargetAdvertiser: '',
      changeDateURL: '',
      startDate: '',
      endDate: '',
      startDateMenu: false,
      endDateMenu: false,
      hideCampaignURL: '',
      moveValidation: '',
      changeValidation: '',
      hideValidation: '',
      searchResults: [],
      unhideCampaignURL: null,
      search: '',
      associateCampaignURL: '',
      associateFormValid: '',
      associateOrderURL: '',
      moveCampaignToProductURL: '',
      selectedProduct: '',
      moveToProductValidation: '',
      isSearching: false,
      campaignURLToClone: '',
      clonedCampignName: '',
      advertiserIdToClone: 'https://demo.analytics.adportal.io/home/?id=88888888',
      cloneFormValid: '',
    };
  },
  watch: {
    async search(newVal) {
      await this.searchHideCampaigns(newVal);
    },
  },
  computed: {
    filteredItems() {
      return this.isSearching ? [] : this.searchResults;
    },
    productList() {
      const list = {
        DISPLAY: 'Display',
        PREROLL: 'Video',
        VIDEO: 'CDV',
        OTT: 'CTV',
        SIMPGEOFENCE: 'Geofence',
        GTDISPLAY: 'Geofence - Display',
        GTVIDEO: 'Geofence - Video',
        SEM: 'SEM',
        GOOGLEVIDEO: 'YouTube',
      };
      return Object.entries(list).map(([key, value]) => ({
        name: value,
        key,
      }));
    },
    isAdminHierarchySU(): boolean {
      return this.$store.state.customer?.user?.is_superuser;
    },
  },
  methods: {
    combinedText(item: HiddenCampaign) {
      return `${item?.campaignId}, ${item?.advertiserId}`;
    },
    isMoveFormValid(): boolean {
      const errors: string[] = [];
      if (!this.isvalidURL(this.moveCampaignURL)) {
        errors.push('Campaign URL is not valid.');
      } else {
        if (!this.getAdvertiserFromURL(this.moveCampaignURL) || !this.getCampaignIdFromURL(this.moveCampaignURL)) {
          errors.push('Campaign URL is missing advertiser ID or campaign ID.');
        }
      }
      if (!this.isvalidURL(this.moveCampaignTargetAdvertiser)) {
        errors.push('Advertiser URL is not valid.');
      } else {
        if (!this.getAdvertiserFromURL(this.moveCampaignTargetAdvertiser)) {
          errors.push('Advertiser URL is missing advertiser ID.');
        }
      }
      if (
        this.isvalidURL(this.moveCampaignURL) &&
        this.isvalidURL(this.moveCampaignTargetAdvertiser) &&
        this.getAdvertiserFromURL(this.moveCampaignURL) === this.getAdvertiserFromURL(this.moveCampaignTargetAdvertiser)
      ) {
        errors.push('Current and Target advertisers are the same.');
      }
      if (errors.length) {
        this.moveValidation = errors.join('<br>');
        return false;
      }
      this.moveValidation = '';
      return true;
    },
    isHideUnhideFormValid(): boolean {
      const errors: string[] = [];
      // Validation of both fields
      if (this.hideCampaignURL && this.unhideCampaignURL) {
        errors.push('Please, add only hide campaign url or choose unhidden campaign');
      }
      // Check only Hide field
      if (this.hideCampaignURL) {
        if (!this.isvalidURL(this.hideCampaignURL)) {
          errors.push('Hide URL is not valid.');
        } else {
          if (!this.hasAdvertiserAndCampaignId(this.hideCampaignURL)) {
            errors.push('Hide URL is missing advertiser ID or Campaign ID.');
          }
        }
      }
      // Check only Unhide field
      if (this.unhideCampaignURL) {
        const unhideArray = this.unhideCampaignURL?.split(',');
        if (!unhideArray.length) {
          errors.push('Unhide is not valid.');
        } else {
          if (unhideArray.length < 1) {
            errors.push('Unhide is missing advertiser ID or Campaign ID.');
          }
        }
      }

      if (errors.length) {
        this.hideValidation = errors.join('<br>');
        return false;
      }
      this.hideValidation = '';
      return true;
    },
    isChangeCampaignFormValid(): boolean {
      const errors: string[] = [];
      if (!this.isvalidURL(this.changeDateURL)) {
        errors.push('Campaign URL is not valid.');
      } else {
        if (!this.getAdvertiserFromURL(this.changeDateURL) || !this.getCampaignIdFromURL(this.changeDateURL)) {
          errors.push('Campaign URL is missing advertiser ID or campaign ID.');
        }
      }
      if (!this.endDate && !this.startDate) {
        errors.push('At least one date is required.');
      }
      if (this.startDate && this.endDate) {
        const startDateAsDate = new Date(this.startDate);
        const endDateAsDate = new Date(this.endDate);
        if (startDateAsDate > endDateAsDate) {
          errors.push("Start date can't exceed end date.");
        }
      }
      if (errors.length) {
        this.changeValidation = errors.join('<br>');
        return false;
      }
      this.changeValidation = '';
      return true;
    },
    isAssociateOrderFormVaid(): boolean {
      const errors: string[] = [];
      if (!this.isvalidURL(this.associateCampaignURL)) {
        errors.push('Campaign URL is not valid.');
      } else {
        if (
          !this.getAdvertiserFromURL(this.associateCampaignURL) ||
          !this.getCampaignIdFromURL(this.associateCampaignURL)
        ) {
          errors.push('Campaign URL is missing advertiser ID or campaign ID.');
        }
      }
      if (!this.isvalidURL(this.associateOrderURL)) {
        errors.push('Order URL is not valid.');
      } else {
        if (!this.getOrderIdFromURL(this.associateOrderURL)) {
          errors.push('Order URL is missing order ID.');
        }
      }
      if (errors.length) {
        this.associateFormValid = errors.join('<br>');
        return false;
      }
      this.associateFormValid = '';

      return true;
    },
    isCloneFormValid(): boolean {
      const errors: string[] = [];
      if (!this.isvalidURL(this.campaignURLToClone)) {
        errors.push('Campaign URL is not valid.');
      } else {
        if (
          !this.getAdvertiserFromURL(this.campaignURLToClone) ||
          !this.getCampaignIdFromURL(this.campaignURLToClone)
        ) {
          errors.push('Campaign URL is missing advertiser ID or campaign ID.');
        }
      }

      if (errors.length) {
        this.cloneFormValid = errors.join('<br>');
        return false;
      }
      this.cloneFormValid = '';

      return true;
    },
    isvalidURL(url: string): boolean {
      try {
        return Boolean(new URL(url));
      } catch (e) {
        return false;
      }
    },
    isMoveToProductFormValid(): boolean {
      const errors: string[] = [];
      if (!this.isvalidURL(this.moveCampaignToProductURL)) {
        errors.push('Campaign URL is not valid.');
      } else {
        if (
          !this.getAdvertiserFromURL(this.moveCampaignToProductURL) ||
          !this.getCampaignIdFromURL(this.moveCampaignToProductURL)
        ) {
          errors.push('Campaign URL is missing advertiser ID or campaign ID.');
        }
      }

      if (!this.selectedProduct) {
        errors.push('Product not selected.');
      }

      if (errors.length) {
        this.moveToProductValidation = errors.join('<br>');
        return false;
      }
      this.moveToProductValidation = '';
      return true;
    },
    hasAdvertiserAndCampaignId(url: string): boolean {
      if (this.getAdvertiserFromURL(url) && this.getCampaignIdFromURL(url)) {
        return true;
      } else {
        return false;
      }
    },
    getAdvertiserFromURL(url: string): string {
      const campaignUrl = new URL(url);
      const advertiserId = campaignUrl.searchParams.get('id');
      return advertiserId ?? '';
    },
    getCampaignIdFromURL(url: string): string {
      const campaignUrl = new URL(url);
      const campaignId = campaignUrl.searchParams.get('viewCampaigns');
      return campaignId ?? '';
    },
    getCampaignTypeFromURL(url: string): string {
      const campaignUrl = new URL(url);
      let campaignType = campaignUrl.searchParams.get('tab');
      campaignType = campaignType === 'emailsi' ? 'siteimpact' : campaignType;
      return campaignType.toUpperCase() ?? '';
    },
    getOrderIdFromURL(url: string): string {
      const orderUrl = new URL(url);
      const orderId = orderUrl.searchParams.get('orderId');
      return orderId ?? '';
    },
    async searchHideCampaigns(val: string) {
      if (this.isLoading) return;
      clearTimeout(this.searchTimeout);
      this.isSearching = true;

      this.searchTimeout = setTimeout(async () => {
        this.isLoading = true;
        try {
          const data = await this.$store.dispatch('linearAdmin/getHiddenCampaigns', {
            search: val,
            limit: 100,
            offset: 0,
          });
          this.searchResults = data?.campaigns || [];
        } catch (error) {
          this.searchResults = [];
        } finally {
          this.isLoading = false;
          this.isSearching = false;
        }
      }, 500);
    },
    async moveCampaign(): Promise<void> {
      if (!this.isMoveFormValid()) return;
      this.$store.dispatch('showError', {
        message: 'Operation started, please wait...',
        errorType: 'success',
      });
      await this.$store
        .dispatch('linearAdmin/moveCampaign', {
          targetAdvertiserId: this.getAdvertiserFromURL(this.moveCampaignTargetAdvertiser),
          currentAdvertiserId: this.getAdvertiserFromURL(this.moveCampaignURL),
          campaignId: this.getCampaignIdFromURL(this.moveCampaignURL),
          campaignType: this.getCampaignTypeFromURL(this.moveCampaignURL),
        })
        .then(res => {
          if (res.error) {
            this.$store.dispatch('showError', {
              message: res.error,
              errorType: 'error',
            });
            return;
          }
          this.$store.dispatch('showError', {
            message: 'Campaign was successfully moved',
            errorType: 'success',
          });
        });
    },
    async hideUnhideCampaign(): Promise<void> {
      if (!this.isHideUnhideFormValid()) return;
      if (this.hideCampaignURL) {
        this.$store.dispatch('showError', {
          message: 'Hide campaign started, please wait...',
          errorType: 'success',
        });
        await this.$store
          .dispatch('linearAdmin/hideCampaign', {
            advertiserId: this.getAdvertiserFromURL(this.hideCampaignURL),
            campaignId: this.getCampaignIdFromURL(this.hideCampaignURL),
          })
          .then(res => {
            if (res.error) {
              this.$store.dispatch('showError', {
                message: res.error,
                errorType: 'error',
              });
              return;
            }
            this.$store.dispatch('showError', {
              message: 'Campaign was successfully hidden',
              errorType: 'success',
            });
            this.searchHideCampaigns(null);
            this.hideCampaignURL = null;
          });
      } else if (this.unhideCampaignURL) {
        const valuesArray = this.unhideCampaignURL.split(',');
        const campaignId = valuesArray[0].trim();
        const advertiserId = valuesArray[1].trim();
        this.$store.dispatch('showError', {
          message: 'Unhide campaign started, please wait...',
          errorType: 'success',
        });
        await this.$store
          .dispatch('linearAdmin/unhideCampaign', {
            advertiserId,
            campaignId,
          })
          .then(res => {
            if (res.error) {
              this.$store.dispatch('showError', {
                message: res.error,
                errorType: 'error',
              });
              return;
            }
            this.$store.dispatch('showError', {
              message: 'Campaign was successfully unhidden',
              errorType: 'success',
            });
            this.searchHideCampaigns(null);
            this.unhideCampaignURL = null;
          });
      }
    },
    async changeCampaignData(): Promise<void> {
      if (!this.isChangeCampaignFormValid()) return;

      const campaignData = {};
      if (this.startDate) campaignData.start_date = this.startDate;
      if (this.endDate) campaignData.end_date = this.endDate;

      this.$store.dispatch('showError', {
        message: 'Operation started, please wait...',
        errorType: 'success',
      });
      await this.$store
        .dispatch('linearAdmin/updateCampaignData', {
          advertiserId: this.getAdvertiserFromURL(this.changeDateURL),
          campaignId: this.getCampaignIdFromURL(this.changeDateURL),
          data: campaignData,
        })
        .then(res => {
          if (res.error) {
            this.$store.dispatch('showError', {
              message: res.error,
              errorType: 'error',
            });
            return;
          }
          this.$store.dispatch('showError', {
            message: 'Campaign data was successfully updated',
            errorType: 'success',
          });
        });
    },
    async associateOrderWithCampaign(): Promise<void> {
      if (!this.isAssociateOrderFormVaid()) return;
      this.$store.dispatch('showError', {
        message: 'Operation started, please wait...',
        errorType: 'success',
      });
      await this.$store
        .dispatch('linearAdmin/associateOrderWithCampaign', {
          currentAdvertiserId: this.getAdvertiserFromURL(this.associateCampaignURL),
          campaignType: this.getCampaignTypeFromURL(this.associateCampaignURL),
          advertiserId: this.getAdvertiserFromURL(this.associateOrderURL),
          orderId: this.getOrderIdFromURL(this.associateOrderURL),
          campaignId: this.getCampaignIdFromURL(this.associateCampaignURL),
        })
        .then(res => {
          if (res.error) {
            this.$store.dispatch('showError', {
              message: res.error,
              errorType: 'error',
            });
            return;
          }
          this.$store.dispatch('showError', {
            message: 'Associate Order with Campaign was successfully been',
            errorType: 'success',
          });
        });
    },
    async moveCampaignToProduct(): Promise<void> {
      if (!this.isMoveToProductFormValid()) return;
      this.$store.dispatch('showError', {
        message: 'Operation started, please wait...',
        errorType: 'success',
      });
      await this.$store
        .dispatch('linearAdmin/moveCampaignToProduct', {
          campaignType: this.getCampaignTypeFromURL(this.moveCampaignToProductURL),
          advertiserId: this.getAdvertiserFromURL(this.moveCampaignToProductURL),
          campaignId: this.getCampaignIdFromURL(this.moveCampaignToProductURL),
          newProduct: this.selectedProduct,
        })
        .then(res => {
          if (res.error) {
            this.$store.dispatch('showError', {
              message: res.error,
              errorType: 'error',
            });
            return;
          }
          this.$store.dispatch('showError', {
            message: 'Campaign was successfully moved',
            errorType: 'success',
          });
        });
    },
    async cloneCampaignToAdvertiser(): Promise<void> {
      if (!this.isCloneFormValid()) return;
      this.$store.dispatch('showError', {
        message: 'Operation started, please wait...',
        errorType: 'success',
      });
      const targetAdvertiserId = this.isvalidURL(this.advertiserIdToClone)
        ? this.getAdvertiserFromURL(this.advertiserIdToClone)
        : this.advertiserIdToClone;

      await this.$store
        .dispatch('linearAdmin/cloneCampaignToAdvertiser', {
          campaignType: this.getCampaignTypeFromURL(this.campaignURLToClone),
          campaignId: this.getCampaignIdFromURL(this.campaignURLToClone),
          sourceAdvertiserId: this.getAdvertiserFromURL(this.campaignURLToClone),
          targetAdvertiserId,
          newCampaignName: this.clonedCampignName,
        })
        .then(res => {
          if (res.error) {
            this.$store.dispatch('showError', {
              message: res.error,
              errorType: 'error',
            });
            return;
          }
          this.$store.dispatch('showError', {
            message: 'Campaign was successfully cloned',
            errorType: 'success',
          });
        });
    },
  },
});
